import React from 'react'
import SEO from '~/components/seo'
import { Wrapper, Desc, Container, Head } from '../styles/staticPage'

const TransactionPage = () => (
  <Wrapper>
    <SEO title="特定商取引法に基づく表記" />
    <Head>特定商取引法に基づく表記</Head>
    <Container>
      <Desc>販売業者：高天原</Desc>
      <Desc>代表責任者：高田一輝</Desc>
      <Desc>所在地：2510861, 神奈川県 藤沢市大庭 5404-3</Desc>
      <Desc>電話番号：08042762318 </Desc>
      <Desc>メールアドレス：takamagahara.stone@gmail.com</Desc>
      <Desc>販売価格：各商品ページをご参照下さい。</Desc>
      <Desc>
        商品代金以外の必要料金 <br />
        消費税（商品代金に含んで表示)・送料
      </Desc>
      <Desc>
        支払方法および支払の時期
        <br />
        クレジットカード：決済時（クレジットカード会社ごとに異なります）
      </Desc>
      <Desc>
        商品の引渡時期 <br />
        お支払い確認後3日以内に発送。変更がある場合ホームページ内で告知。
      </Desc>
      <Desc>
        返品・不良品・キャンセルについて <br />
        商品発送後の返品・返却等はお受けいたしかねます。商品が破損の場合のみ返品を受付いたします（天然石の為交換はできません）。キャンセルは注文後２４時間以内に限り受付いたします。返金方法については、個別に対応させていただきます。
      </Desc>
      <Desc>返品期限：商品到着より７日以内にご連絡下さい。</Desc>
    </Container>
  </Wrapper>
)

export default TransactionPage
